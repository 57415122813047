import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import Banner from "../components/banner";

const IndexPage = () => (
  <Layout>
    <Seo title="Geschenke" />
    <Banner illustration={
      <StaticImage
          src={`../images/illustrations/LoveGift.png`}
          loading="eager"
          height={128}
          quality={98}
          formats={["auto", "webp", "avif"]}
          alt="Love"
      />
    } title="Geschenke" />
    <div>
      Tipps, falls ihr nach einer Geschenkidee sucht
        <br/>
      <h3>Schlecht:</h3>
      <ul>
        <li>Bettwäsche</li>
        <li>Kaffeemaschine</li>
        <li>Geschirr</li>
        <li>Vasen</li>
      </ul>
        <h3>Gut:</h3>
        <ul>
            <li>Fränkli</li>
            <li>Höger</li>
            <li>Müüs</li>
            <li>Batzeli</li>
            <li>Stützli</li>
            <li>Chlütterli</li>
            <li>Nötli</li>
            <li>Zuestüpfli</li>
            <li>Goldbäreli</li>
        </ul>

        ❤️ Wir würden uns über einen Beitrag an unsere Flitterwochen freuen. ❤️
        <br /><br />
        Zahlungsangaben: <br />
        CH71 0028 5285 1166 9240 D<br />
        Katherine Tang & Michel Kern <br />
        Freilagerstrasse 57 <br/>
        8047 Zürich
    </div>

  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Geschenke" />

export default IndexPage
